import { combineReducers } from '@reduxjs/toolkit'
// PLantTypes reducers
import { reducer as pvPlantCreationReducer } from '@slices/pv/plantCreation'
import { reducer as pvPlantViewReducer } from '@slices/pv/plantView'
import { reducer as pvLightPlantCreationReducer } from '@slices/pv-light/plantCreation'
import { reducer as pvLightPlantViewReducer } from '@slices/pv-light/plantView'
import { reducer as pvProLightPlantCreationReducer } from '@slices/pv-pro-light/plantCreation'
import { reducer as pvProLightPlantViewReducer } from '@slices/pv-pro-light/plantView'
import { reducer as pvProRealtimePlantCreationReducer } from '@slices/pv-pro-realtime/plantCreation'
import { reducer as pvProRealtimePlantViewReducer } from '@slices/pv-pro-realtime/plantView'
import { reducer as ledPlantReducer } from '@slices/led/plant' // creation
import { reducer as ledPlantViewReducer } from '@slices/led/plantView'
import { reducer as hydroPlantViewReducer } from '@slices/hydro/plantView'
import { reducer as hydroPlantReducer } from '@slices/hydro/plantCreation'

import { reducer as mapsReducer } from '@slices/maps'
import { reducer as systemsListReducer } from '@slices/systems'

const rootReducer = combineReducers({
  systemsList: systemsListReducer,
  pvPlantCreation: pvPlantCreationReducer,
  pvPlantView: pvPlantViewReducer,
  pvLightPlantCreation: pvLightPlantCreationReducer,
  pvLightPlantView: pvLightPlantViewReducer,
  pvProLightPlantCreation: pvProLightPlantCreationReducer,
  pvProLightPlantView: pvProLightPlantViewReducer,
  pvProRealtimePlantCreation: pvProRealtimePlantCreationReducer,
  pvProRealtimePlantView: pvProRealtimePlantViewReducer,
  ledPlant: ledPlantReducer, // creation
  ledPlantView: ledPlantViewReducer,
  hydroPlantView: hydroPlantViewReducer,
  hydroPlantCreation: hydroPlantReducer,
  maps: mapsReducer
})

export default rootReducer
