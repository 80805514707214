import log from '@pelv/frontlog'
import { createSlice } from '@reduxjs/toolkit'
import api from '@micmnt/apis'

const initialState = {
  plants: ['-'],
  plantsCount: 0,
  isLoading: false
}

const slice = createSlice({
  name: 'systemsList',
  initialState,
  reducers: {
    setLoading (state, action) {
      const { isLoading } = action.payload
      state.isLoading = isLoading
    },
    setPlantsCount (state, action) {
      const { plantsCount } = action.payload
      state.plantsCount = plantsCount
    },
    removeCreatedPlant (state, action) {
      const { plantId } = action.payload
      const currentPlants = Object.assign([], state.plants)
      const plantIndex = currentPlants.findIndex(el => el.uuid === plantId)
      currentPlants.splice(plantIndex, 1)
      state.plants = currentPlants
    },
    setPlants (state, action) {
      const { plants } = action.payload
      log({ text: 'plants => ', variable: plants, tag: 'plantsSlice' })
      state.plants = plants
    },
    pushCreatedPlant (state, action) {
      const { newPlant } = action.payload
      const currentPlants = Object.assign([], state.plants)
      currentPlants.unshift(newPlant)
      state.plants = currentPlants
    },
    updatePlantList (state, action) {
      const { updatedPlant } = action.payload
      const currentPlants = Object.assign([], state.plants)
      const currentPlantUuid = updatedPlant.uuid
      const currentPlantIndex = currentPlants.findIndex(el => el.uuid === currentPlantUuid)
      if (currentPlantIndex > -1) {
        currentPlants.splice(currentPlantIndex, 1, updatedPlant)
      }
      state.plants = currentPlants
    }
  }
})

export const reducer = slice.reducer

// Action che elimina un impianto e aggiorna la lista degli impianti
export const deletePlant = (plantId) => async (dispatch) => {
  await api.delete({ savedUrl: 'plantsList', path: `/${plantId}` })
  dispatch(slice.actions.removeCreatedPlant({ plantId }))
}
// Action per prendere il numero di impianti disponibili
export const getPlantsCount = ({ q = null }) => async dispatch => {
  const params = {}
  if (q) {
    params.q = q
  }
  const { data } = await api.get({ savedUrl: 'plantsList', path: '/count', params })
  const plantsCount = data?.count || 0
  dispatch(slice.actions.setPlantsCount({ plantsCount }))
}
// Action per fare il fetch degli impianti
export const getPlants = ({ q = null, sortorder = 'DESC', skip = 0, limit = 20, filter = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortby: 'metadata.startDate',
    sortorder: sortorder.toUpperCase()
  }
  if (q) {
    params.q = q
  }
  if (filter) {
    params.filter = filter
  }
  dispatch(slice.actions.setLoading({ isLoading: true }))
  const { data: plants } = await api.get({ savedUrl: 'plantsList', params })
  if (plants) {
    dispatch(slice.actions.setPlants({ plants, isFilter: filter }))
  }
  dispatch(slice.actions.setLoading({ isLoading: false }))
}
// Action che aggiorna la lista degli impianti dopo una modifica
export const updatePlantList = (updatedPlant) => dispatch => {
  dispatch(slice.actions.updatePlantList({ updatedPlant }))
}
// Action che aggiunge l'impianto appena creato nella lista degli impianti
export const pushCreatedPlant = (newPlant) => dispatch => {
  dispatch(slice.actions.pushCreatedPlant({ newPlant }))
}
