import clsx from 'clsx'
import { Box, Checkbox, Grid, ListItemIcon, ListItemText, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'

const useStyles = makeStyles(() => ({
  root: {}
}))

function SensorContent ({ className, ...rest }) {
  const classes = useStyles()

  const { selectedObject, assets, element, setElement } = rest

  const configAssets = element?.config?.[0]?.assets || []

  const selected = configAssets?.[0]?.assetId || ''

  const number = element?.config?.[0]?.data?.draw?.number || 0

  const color = element?.config?.[0]?.data?.draw?.backgroundColor || 'white'

  const [selectedAsset, setSelectedAsset] = useState(selected)
  const [currentNumber, setCurrentNumber] = useState(number)
  const [currentColor, setCurrentColor] = useState(color)

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant='h6'>Meter</Typography>
          <Box my={2} width='100%'>
            <TextField
              size='small'
              color='primary'
              variant='outlined'
              select
              fullWidth
              SelectProps={{
                renderValue: (renderSelected) => <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>{assets?.find(asset => asset.uuid === renderSelected)?.name || ''}</ListItemText>
              }}
              label='Seleziona sensore di livello'
              value={selectedAsset}
            >
              {(assets || []).map(el => (
                <MenuItem
                  dense
                  onClick={() => {
                    let isDeselection = false
                    setElement(prevElement => {
                      // oggetto completo del device selezionato
                      const selectedAsset = assets.find(asset => asset.uuid === el.uuid)

                      // se l'oggetto esiste, devo aggiornare l'array dei devices nell'elemento
                      if (selectedAsset && selectedAsset !== undefined) {
                        // strutturo l'oggetto device
                        const elementAsset = {
                          type: selectedObject.type,
                          // model: selectedAsset.assetType.name,
                          assetId: selectedAsset.uuid,
                          properties: selectedAsset.assetType?.properties,
                          additionalData: {}
                        }

                        if (prevElement.config.length > 0) {
                          const sameDevice = prevElement?.config?.[0]?.assets?.find(configEl => configEl.assetId === el.uuid)
                          // se è presente lo rimuovo
                          if (sameDevice && sameDevice !== undefined) {
                            const newAssets = prevElement?.config?.[0]?.assets?.filter(asset => asset.assetId !== el.uuid)
                            prevElement.config[0].assets = newAssets
                            isDeselection = true
                          } else {
                            // aggiorno l'array di devices di un singolo elemento
                            prevElement.config[0].assets = [{ ...elementAsset }]
                          }
                        } else {
                          prevElement.config.push({
                            assets: [{ ...elementAsset }],
                            data: {}
                          })
                        }
                      }

                      return ({
                        config: [{
                          ...prevElement.config[0]
                        }]
                      })
                    })
                    if (isDeselection) {
                      setSelectedAsset('')
                    } else {
                      setSelectedAsset(el.uuid)
                    }
                  }} key={el.uuid} value={el.uuid}
                >
                  <ListItemIcon>
                    <Checkbox
                      color='primary'
                      edge='start'
                      checked={selected === el.uuid}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText>{el.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box width='100%'>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Numero'
              type='number'
              placeholder='0'
              value={currentNumber || ''}
              onChange={(e) => {
                e.persist()
                setElement(prevElement => {
                  if (prevElement.config.length > 0) {
                    // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      number: e.target.value
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          number: e.target.value
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
                setCurrentNumber(e.target.value)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore di sfondo</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '100px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default SensorContent
