import moment from 'moment'
import SunCalc from 'suncalc'
import { CornerUpRight as CornerUpRightIcon, TrendingUp as TrendingUpIcon, Activity as ActivityIcon, Sun as SunIcon } from 'react-feather'
import Overview from './Overview'
import Production from './Production'
import { europeNum } from '@/utils/general'

// funzione che torna la durata formattata
export const tabs = [
  {
    value: 'overview',
    label: 'Panoramica',
    isSmall: true
  },
  {
    value: 'production',
    label: 'Produzione',
    isSmall: true
  }
  /* {
    value: 'anomalies',
    label: 'Anomalie',
    isSmall: true
  },
  {
    value: 'analytics',
    label: 'Analytics',
    isSmall: false
  } */
]

export const selectActiveTab = (currentTab, hasGlass = false) => {
  switch (currentTab) {
    case 'overview': {
      return (
        <Overview />
      )
    }
    case 'production': {
      return (
        <Production hasGlass={hasGlass} />
      )
    }
    /* case 'anomalies': {
      return (
        <Anomalies hasGlass={hasGlass} />
      )
    } */
    /* case 'analytics': {
      return (
        <Analytics hasGlass={hasGlass} />
      )
    } */
    default:
      return null
  }
}

export const returnDuration = (duration) => {
  const toReturn = Number(duration)
  if (duration === '' || isNaN(toReturn)) {
    return duration
  } else {
    return moment.duration(toReturn).humanize()
  }
}

// funzione che ritorna i dati per la lista delle anomalie sistemati per la view della lista
export const normalizeAnomaly = (anomaly, categories) => {
  // mi preparo l'oggetto finale
  const finalObj = {
    ...anomaly,
    name: 'N.F.',
    description: 'N.F.',
    category: '',
    subCategory: {
      label: '',
      color: ''
    },
    selected: true
  }
  // cerco config e categorie
  const thisConfig = anomaly.configuration
  if (thisConfig) {
    finalObj.name = thisConfig.name
    finalObj.description = thisConfig.description
    // cerco la categoria principale
    const thisCategory = categories.find(
      (category) => category.name === thisConfig.category
    )
    if (thisCategory) {
      finalObj.category = thisCategory.label
      // cerco la subcategory
      const thisSubCategory = thisCategory.subCategory.find(
        (sub) => sub.name === thisConfig.subCategory
      )
      if (thisSubCategory) {
        finalObj.subCategory.label = thisSubCategory.label
        finalObj.subCategory.color = thisSubCategory.color
      }
    }
  }
  // mi sistemo la durata nel caso sia ancora aperta
  if (!finalObj.duration && (!finalObj.endedAt || finalObj.endedAt === '')) {
    // DA RIMUOVERE -> ref usato per i dati demo
    const dateRef = moment()
    finalObj.duration = moment
      .duration(moment(dateRef).diff(moment(finalObj.startedAt)))
      .asMilliseconds()
    // finalObj.duration = moment.duration(finalObj.duration).asHours()
  }

  return finalObj
}

// Funzione che prende in ingresso una data di inizio e una data di fine e ritorna il tipo di aggregazione da utilizzare
export const getAggregationTypeFromPeriod = (startDate, endDate) => {
  const daysDiff = moment(endDate).diff((moment(startDate)), 'days')
  const hoursDiff = moment(endDate).diff(moment(startDate), 'hours')

  // Se il periodo è maggiore di un giorno richiedo i dati in forma giornaliera
  if (daysDiff > 1) {
    return 'daily'
  }
  // Se il periodo è maggiore di 12 ore richiedo i dati in forma oraria
  if (hoursDiff > 12) {
    return 'hourly'
  }

  // Se il periodo è mggiore di 1 ora richiedo i dati in forma quartoraria
  if (hoursDiff > 1) {
    return 'quarter'
  }

  // Se sono sotto 1 ora richiedo i dati puntuali
  return 'raw'
}

function getPlantSunTimes (plant, _now) {
  const now = new Date(_now)
  let sunrise = new Date(now)
  sunrise.setHours(5, 0, 0, 0)
  let sunset = new Date(now)
  sunset.setHours(22, 0, 0, 0)
  if (plant.location && plant.location.coordinates) {
    const suntimes = SunCalc.getTimes(new Date(now), plant.location.coordinates[1], plant.location.coordinates[0])
    sunrise = new Date(suntimes.sunrise)
    sunset = new Date(suntimes.sunset)
  }

  let sunriseMinutesOffset = 90
  let sunsetMinutesOffset = 90
  if (plant.metadata && plant.metadata.sunriseMinutesOffset && !isNaN(plant.metadata.sunriseMinutesOffset)) {
    sunriseMinutesOffset = Number(plant.metadata.sunriseMinutesOffset)
  }
  if (plant.metadata && plant.metadata.sunsetMinutesOffset && !isNaN(plant.metadata.sunsetMinutesOffset)) {
    sunsetMinutesOffset = Number(plant.metadata.sunsetMinutesOffset)
  }
  sunrise.setMinutes(sunrise.getMinutes() + sunriseMinutesOffset)
  sunset.setMinutes(sunset.getMinutes() - sunsetMinutesOffset)
  return { sunset, sunrise }
}

function isNightAtThePlant (plant, _now) {
  const now = new Date(_now)
  const { sunset, sunrise } = getPlantSunTimes(plant, now)
  // alog(`Dopo aver applicato gli offset, per l'impianto abbiamo sunrise=${sunrise.toISOString()} e sunset=${sunset.toISOString()}`, null, 'plant')
  return (now.getTime() < sunrise.getTime() || now.getTime() > sunset.getTime())
}
// funzione che ritorna la durata effettiva dell'anomalia (tiene conto solo delle ore di esercizio)
export const getAnomalyDuration = (_from, _to, plant) => {
  const from = new Date(_from)
  const to = new Date(_to)

  const cursor = new Date(from)
  let totalMinutes = 0
  while (cursor.getTime() < to.getTime()) {
    if (!isNightAtThePlant(plant, cursor)) {
      totalMinutes += 15
    }
    cursor.setMinutes(cursor.getMinutes() + 15)
  }

  // Diamo l'ouput in ms
  return totalMinutes * 60 * 1000
}

// Funzione che presi in ingresso il typo di periodo e la data di inizio ritornano data di inizio e data di fine
export const getDatesFromPeriod = (period, now, formatString = null) => {
  let maxDate, minDate
  if (period === 'live') {
    // min e max saranno la mezzanotte di oggi e le 23:59 di oggi
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
    // maxDate = moment(now).add(1, 'day')
    maxDate = moment(now).set({ hours: 23, minute: 59, second: 59, millisecond: 900 })
  } else if (period === 'week') {
    minDate = moment(now).startOf('week')
    maxDate = moment(now).endOf('week')
  } else if (period === 'month') {
    minDate = moment(now).startOf('month')
    maxDate = moment(now).endOf('month')
  } else if (period === 'year') {
    // sarà l'anno corrente
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
    minDate = moment(minDate)
      .month(0)
      .date(1)
    maxDate = moment(now).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    maxDate = moment(maxDate)
      .month(11)
      .date(31)
  }

  return formatString
    ? { minDate: moment(minDate).format(formatString), maxDate: moment(maxDate).format(formatString) }
    : { minDate: moment(minDate).toISOString(), maxDate: moment(maxDate).toISOString() }
}

// Funzione che prende in ingresso i dati ricevuti dall'api e li formatta per le card
export const decodeCardsFromApi = (dataObj) => {
  // Oggetto che contiene la configurazione delle cards
  const labelIconConfig = {
    activePower: {
      icon: <ActivityIcon />,
      label: 'Potenza',
      uom: 'kW'
    },
    payload: {
      icon: <CornerUpRightIcon />,
      label: 'Portata',
      uom: `m${String.fromCodePoint(0x00B3)}/s`
    },
    performance: {
      icon: <TrendingUpIcon />,
      label: 'Rendimento',
      uom: '%'
    },
    saltoMonteGriglia: {
      icon: <SunIcon />,
      label: 'Salto Monte griglia',
      uom: 'm'
    }
  }

  return Object.keys(dataObj).map((key) => ({
    icon: (labelIconConfig[key] && labelIconConfig[key].icon) || null,
    values: [
      {
        label: (labelIconConfig[key] && labelIconConfig[key].label) || '',
        value: dataObj[key] !== '-'
          ? `${europeNum(dataObj[key])} ${labelIconConfig[key]?.uom || ''}`
          : '-'
      }
    ]
  }))
}

// Funzione che prende in ingresso una serie e ritorna una nuova serie con i valori cumulati nel tempo
export const calculateCumulatedSerie = (serie = []) => {
  let cumulatedValue = 0

  return serie.map((serieEl, serieIndex) => {
    if (serieIndex === 0) {
      cumulatedValue = Number(serieEl.value)
      return serieEl
    }

    cumulatedValue += Number(serieEl.value)
    return {
      ...serieEl,
      value: cumulatedValue
    }
  })
}

// Funzione che prende in ingresso un oggetto di configurazione ed una serie e ritorna un oggetto interpretabile dalla libreria grafica
export const generateChartSerie = (serie, config) => {
  return ({
    ...config,
    data: serie
  })
}

// Funzione che prende in ingresso la configurazione e ritorna le serie che sono disabilitate alla partenza
export const getDisabledSeries = (config) => {
  return Object.keys(config).reduce(
    (acc, key) => {
      if (config[key].initialVisibility === false) {
        acc.push(config[key].name)
      }
      return acc
    },
    []
  )
}
