import { convertToSVGPoint, generateLine, generateVerticalBarPath, generateVerticalLine, getSerieMaxValue, getValuePosition } from './utils'
import { basicColors } from '@/theme'
import { Fragment, useRef, useState } from 'react'
import { Box, Typography } from '@material-ui/core'

const defaultWidth = 792
const defaultHeight = 523

const LineAndBarChart = (props) => {
  const { width = defaultWidth, height = defaultHeight, data = {}, initialHiddenSeries = [] } = props

  const { series, labels } = data

  const svgContainer = useRef(null)
  const legendContainerRef = useRef(null)

  const padding = 20
  const yInterval = 8

  // Numero di assi a sinistra del grafico
  const leftAxisNum = series?.length % 2 === 0 ? series.length / 2 : (Math.floor(series.length / 2)) + 1
  // Numero di assi a destra del grafico
  const rightAxisNum = Math.floor(series.length / 2)

  const chartXStart = 3 * padding * leftAxisNum

  const chartXEnd = width - 3 * padding * rightAxisNum

  const chartYEnd = height - 4 * padding

  const axisPaths = []
  const seriesPath = []
  const dataPoints = {}

  const [seriesToHide, setSeriesToHide] = useState(initialHiddenSeries)

  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipSerieElement, setTooltipSerieElement] = useState({})
  const [svgMousePosition, setSvgMousePosition] = useState({ x: 0, y: 0 })

  const toggleSerie = (serieName) => {
    if (seriesToHide.includes(serieName)) {
      setSeriesToHide(seriesToHide.filter(el => el !== serieName))
    } else {
      setSeriesToHide([...seriesToHide, serieName])
    }
  }

  if (!height) return null

  const xAxisInterval = (chartXEnd - chartXStart) / labels.length || 1

  const dataLabelPoints = labels.map((label, labelIndex) => ({
    value: label,
    x: xAxisInterval * labelIndex + chartXStart + padding,
    y: chartYEnd + padding
  }))

  let legendElementsX = 0

  // funzione che genera i dataPaths in base al tipo di serie da graficare
  const generateDataPaths = (serie, serieMaxValue, serieIndex) => {
    const paths = serie.data
      .map((serieEl, serieElIndex) => {
        const value = getValuePosition(serieMaxValue, serieEl.value, chartYEnd - padding)

        const serieY = chartYEnd - value

        if (serie.type === 'bar') {
          const barWidth = padding / 2
          const serieElX = xAxisInterval * serieElIndex + barWidth * (serieIndex + 1) + chartXStart
          return generateVerticalBarPath(serieElX, serieY, barWidth, chartYEnd)
        } else {
          const serieElX = xAxisInterval * serieElIndex + padding + chartXStart
          const point = [serieElX, serieY]

          dataPoints[serie.name].push(point)

          return serieElIndex === 0 ? `M ${serieElX} ${serieY}` : generateLine(serieElX, serieY)
        }
      })

    return serie.type === 'bar' ? paths : [paths.join()]
  }

  // funzione che genera gli assi di un grafico
  const generateYAxis = (serie, serieMaxValue, serieIndex) => {
    const yAxisInterval = (chartYEnd - padding) / yInterval

    const isOppositeAxis = serieIndex % 2 !== 0

    /* Creazione degli assi */
    const axisX = isOppositeAxis ? chartXEnd : chartXStart
    const axisPath = generateVerticalLine(axisX, chartYEnd, 0)

    const axisLabelsX = isOppositeAxis ? axisX + 3 / 2 * padding * (serieIndex - 1) + padding / 3 : axisX - 3 / 2 * padding * serieIndex - padding / 3

    const nameLabelX = isOppositeAxis ? axisLabelsX + 2 * padding : axisLabelsX - 2 * padding

    const nameLabelAxisPath = generateVerticalLine(nameLabelX, 0, height - 3 * padding)

    const yAxisLabels = [...Array(yInterval)].map((_, index) => ({
      value: serie.format(Math.round(serieMaxValue / yInterval * index)),
      x: axisLabelsX,
      y: chartYEnd - yAxisInterval * index
    })).concat({
      value: serie.format(Math.round(serieMaxValue)),
      x: axisLabelsX,
      y: chartYEnd - yAxisInterval * yInterval
    })

    return ({
      valueLabels: yAxisLabels,
      isOpposite: isOppositeAxis,
      uom: serie.uom,
      name: serie.name,
      path: axisPath,
      nameLabelPath: nameLabelAxisPath
    })
  }

  series.forEach((serie, serieIndex) => {
    if (!dataPoints[serie.name] || !Array.isArray(dataPoints[serie.name])) {
      dataPoints[serie.name] = []
    }
    const serieMaxValue = getSerieMaxValue(serie.data || [])

    const serieAxis = generateYAxis(serie, serieMaxValue, serieIndex)

    /* Creazione degli assi */
    // if (serie.initialVisibility !== false) {
    axisPaths.push(serieAxis)
    // }

    /* Creazione della serie */
    const dataPaths = generateDataPaths(serie, serieMaxValue, serieIndex)

    seriesPath.push({
      ...serie,
      legendX: legendElementsX,
      paths: dataPaths
    })

    legendElementsX += ((legendContainerRef?.current?.children?.[serieIndex]?.getBBox()?.width || 0) + padding)
  })

  const viewBox = `0 0 ${width} ${height}`

  return (
    <>
      {showTooltip
        ? (
          <Box
            px={2}
            py={1}
            id='tooltip'
            style={{
              backgroundColor: 'rgba(0,0,0,0.7)',
              top: height / 2,
              left: chartXStart,
              display: 'block',
              position: 'absolute',
              borderRadius: 12
            }}
          >
            <Typography style={{ fontWeight: 600 }} variant='body2' component='span'>{tooltipSerieElement?.label || '-'}</Typography>
            <Box mt={2}>
              {seriesPath.filter(serie => !seriesToHide.includes(serie.name)).map((serie, serieIndex) => {
                const pointValue = serie?.data?.[tooltipSerieElement?.index]?.value !== undefined
                  ? serie?.data?.[tooltipSerieElement?.index]?.value
                  : null
                const pointFormattedValue = pointValue !== null ? `${serie.format(pointValue)} ${serie.uom || ''}` : '-'

                return (
                  <Box key={`tooltip-data-${serieIndex}-${serie.name}`} display='flex' alignItems='center' gridRowGap={10} gridColumnGap={10}>
                    <Box style={{ width: 10, height: 10, backgroundColor: serie.color, borderRadius: 5 }} />
                    <Typography variant='body2' component='span'>
                      {`${serie.name}: ${pointFormattedValue}`}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
          )
        : null}
      <svg
        ref={svgContainer}
        viewBox={viewBox}
        width={width}
        height={height}
        onMouseMove={(event) => {
          const { clientX, clientY } = event
          const svgPoint = convertToSVGPoint(svgContainer.current, clientX, clientY)
          setShowTooltip(true)
          setSvgMousePosition(svgPoint)
          const tooltip = document.getElementById('tooltip')
          if (tooltip) {
            const tooltipX = svgPoint.x < (chartXEnd - chartXStart) / 2 ? svgPoint.x + 50 : svgPoint.x - 50 - tooltip.clientWidth
            const tooltipY = svgPoint.y < chartYEnd ? svgPoint.y : chartYEnd
            tooltip.style.top = `${tooltipY}px`
            tooltip.style.left = `${tooltipX}px`
          }
        }}
      >
        <g ref={legendContainerRef} transform={`translate(${width / 2 - legendElementsX / 2})`}>
          {seriesPath.map((serie, serieIndex) => (
            <g
              key={`serie-${serieIndex}-legend}`}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleSerie(serie.name)}
              x={3 * padding}
              y={height}
            >
              <circle cx={5 + serie.legendX} cy={height - 5} r={5} fill={seriesToHide.includes(serie.name) ? basicColors.disabled : serie.color} />
              <text x={15 + serie.legendX} y={height} fill={basicColors.blueGrayLight}>{serie.name}</text>
            </g>
          ))}
        </g>
        {dataLabelPoints.map((label, labelIndex) => (
          <Fragment key={`${label}-${labelIndex}`}>
            {dataLabelPoints.length > 20
              ? (
                <>
                  <defs>
                    <path id={`xAxisLabel-${labelIndex}`} d={`M ${label.x - 40} ${label.y + 20} L ${label.x} ${label.y}`} />
                  </defs>
                  <use href={`#xAxisLabel-${labelIndex}`} fill='none' />
                  <text fontSize={12} fill={basicColors.blueGrayLight}>
                    <textPath textAnchor='start' x={label.x} y={label.y} href={`#xAxisLabel-${labelIndex}`}>{label.value}</textPath>
                  </text>
                </>
                )
              : (
                <text textAnchor='middle' fontSize={12} x={label.x} y={label.y} fill={basicColors.blueGrayLight}>{label.value}</text>
                )}
            <>
              <path d={`M ${label.x} ${chartYEnd + padding * 1 / 3} V 0`} strokeWidth={1} strokeDasharray={5} stroke={basicColors.blueGrayMedium} />
              <rect
                onMouseEnter={(e) => {
                  setTooltipSerieElement({
                    index: labelIndex,
                    label: label.value
                  })
                }}
                x={label.x - xAxisInterval / 2}
                y={0}
                width={xAxisInterval - 5}
                height={chartYEnd}
                fill='transparent'
              />
            </>
          </Fragment>
        ))}
        {
          axisPaths.map((el, axisIndex) => el.valueLabels.map((valueLabel, valueLabelIndex) => valueLabelIndex > 0
            ? (
              <Fragment key={`axis-${axisIndex}-${valueLabelIndex}`}>
                <text textAnchor={el.isOpposite ? 'start' : 'end'} fontSize={12} x={valueLabel.x} y={valueLabel.y} fill={basicColors.blueGrayLight}>{valueLabel.value}</text>
                {axisIndex === 0 ? <path d={`M ${chartXStart - padding / 4} ${valueLabel.y} H ${chartXEnd + padding / 4}`} strokeWidth={1} strokeDasharray={5} stroke={basicColors.blueGrayMedium} /> : null}
              </Fragment>)
            : null))
        }
        {
          svgMousePosition.x > chartXStart && svgMousePosition.x < chartXEnd
            ? <path d={`M ${svgMousePosition.x} ${chartYEnd} V 0`} strokeWidth={1} strokeDasharray={5} stroke={basicColors.blueGrayLight} />
            : null
        }
        {
          svgMousePosition.y > 0 && svgMousePosition.y < chartYEnd
            ? <path d={`M ${chartXStart} ${svgMousePosition.y} H ${chartXEnd}`} strokeWidth={1} strokeDasharray={5} stroke={basicColors.blueGrayLight} />
            : null
        }
        {
          seriesPath.filter(serie => !seriesToHide.includes(serie.name)).map((serie, serieIndex) => serie.paths.map((seriePath, seriePathIndex) => (
            <Fragment key={`serie-${serieIndex}-point-${seriePathIndex}`}>
              {serie.type === 'bar'
                ? <path d={seriePath} fill={serie.color} />
                : <path strokeLinecap='round' strokeLinejoin='round' d={seriePath} strokeWidth={3} fill='none' stroke={serie.color} />}
              {dataPoints[serie.name].map((dataPoint, dataPointIndex) => (
                <circle
                  key={`point-${dataPointIndex}`}
                  cx={dataPoint[0]}
                  cy={dataPoint[1]}
                  r={tooltipSerieElement.index === dataPointIndex ? 7 : 0}
                  fillOpacity={0.9}
                  fill={basicColors.blueGrayLight}
                  stroke={serie.color}
                  strokeWidth={2}
                />
              ))}
            </Fragment>
          )
          ))
        }
        <path d={`M ${chartXStart} ${chartYEnd} H ${chartXEnd}`} strokeWidth={2} stroke={basicColors.blueGrayLight} />
        {
          axisPaths.map((el, axisIndex) => (
            <Fragment key={`axis-${axisIndex}`}>
              <defs>
                <path d={el.nameLabelPath} id={`axis-${axisIndex}`} />
              </defs>
              <use href={`#axis-${axisIndex}`} fill='none' />
              <text fontSize={12} fill={basicColors.blueGrayLight} fontWeight={600}>
                <textPath startOffset='50%' textAnchor='middle' href={`#axis-${axisIndex}`} alignmentBaseline='central'>{`${el.name} (${el.uom})`}</textPath>
              </text>
              <path d={el.path} strokeWidth={2} stroke={basicColors.blueGrayLight} />
            </Fragment>
          )
          )
        }
      </svg>
    </>
  )
}

export default LineAndBarChart
