import { createSlice } from '@reduxjs/toolkit'
import { decodeFromApi, encodeForApi } from '@/utils/adapters/hydro/plantAdapter'
import api from '@micmnt/apis'
import log from '@pelv/frontlog'
import { deepEqual } from '@/utils/objDeepComparison'

export const basePlanimetry = {
  draw: [
    {
      id: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
      type: 'group',
      angle: 0,
      left: -1.3209042865532865,
      top: 213.3350946377916,
      scaleX: 0.7653088105029673,
      scaleY: 0.7653088105029673,
      selectable: false,
      objects: [
        {
          type: 'path',
          angle: 0,
          top: -245.75,
          left: -605.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#636631',
          strokeWidth: 1,
          path: [
            [
              'M',
              0,
              490.5
            ],
            [
              'L',
              0,
              0
            ],
            [
              'L',
              31.5,
              47.5
            ],
            [
              'L',
              67.5,
              47.5
            ],
            [
              'L',
              129.5,
              110.5
            ],
            [
              'L',
              149.5,
              110.5
            ],
            [
              'L',
              185,
              71.5
            ],
            [
              'L',
              250,
              47.5
            ],
            [
              'L',
              367.5,
              71.5
            ],
            [
              'L',
              399,
              123
            ],
            [
              'L',
              431.5,
              154.5
            ],
            [
              'L',
              453,
              224
            ],
            [
              'L',
              930.5,
              234
            ],
            [
              'L',
              1115,
              426.5
            ],
            [
              'L',
              1156,
              375
            ],
            [
              'L',
              1210.5,
              367.5
            ],
            [
              'L',
              1210.5,
              490.5
            ],
            [
              'L',
              0,
              490.5
            ],
            [
              'Z'
            ]
          ],
          width: 1210.5,
          height: 490.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -206.25,
          left: 181.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#CDCDCD',
          strokeLinejoin: 'bevel',
          strokeWidth: 2,
          path: [
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              417.649,
              1.96021
            ],
            [
              'L',
              429.144,
              1.96021
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              366.17,
              75.3853
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              443.138,
              75.3853
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              403.654,
              75.3853
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              366.17,
              75.3853
            ],
            [
              'L',
              366.17,
              90.37
            ],
            [
              'M',
              366.17,
              75.3853
            ],
            [
              'L',
              480.622,
              75.3853
            ],
            [
              'M',
              403.654,
              121.338
            ],
            [
              'L',
              378.665,
              199.758
            ],
            [
              'L',
              385.162,
              199.758
            ],
            [
              'L',
              423.146,
              147.811
            ],
            [
              'M',
              403.654,
              121.338
            ],
            [
              'L',
              423.146,
              147.811
            ],
            [
              'M',
              403.654,
              121.338
            ],
            [
              'L',
              403.654,
              113.846
            ],
            [
              'M',
              423.146,
              147.811
            ],
            [
              'L',
              461.63,
              199.758
            ],
            [
              'L',
              468.128,
              199.758
            ],
            [
              'L',
              443.138,
              121.338
            ],
            [
              'M',
              423.146,
              147.811
            ],
            [
              'L',
              443.138,
              121.338
            ],
            [
              'M',
              403.654,
              113.846
            ],
            [
              'L',
              443.138,
              75.3853
            ],
            [
              'M',
              403.654,
              113.846
            ],
            [
              'L',
              403.654,
              75.3853
            ],
            [
              'M',
              443.138,
              75.3853
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              443.138,
              75.3853
            ],
            [
              'L',
              443.138,
              113.846
            ],
            [
              'M',
              443.138,
              75.3853
            ],
            [
              'L',
              492.618,
              113.846
            ],
            [
              'L',
              492.618,
              130.829
            ],
            [
              'M',
              403.654,
              75.3853
            ],
            [
              'L',
              354.175,
              113.846
            ],
            [
              'L',
              354.175,
              130.829
            ],
            [
              'M',
              403.654,
              75.3853
            ],
            [
              'L',
              443.138,
              113.846
            ],
            [
              'M',
              403.654,
              75.3853
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              443.138,
              41.4199
            ],
            [
              'L',
              480.622,
              75.3853
            ],
            [
              'M',
              480.622,
              75.3853
            ],
            [
              'L',
              480.622,
              90.37
            ],
            [
              'M',
              443.138,
              121.338
            ],
            [
              'L',
              443.138,
              113.846
            ]
          ],
          width: 138.44299999999998,
          height: 197.79779000000002,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -115.25,
          left: 91.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: 'black',
          strokeLinejoin: 'round',
          strokeWidth: 2,
          path: [
            [
              'M',
              263.712,
              161.298
            ],
            [
              'L',
              263.712,
              93.3669
            ],
            [
              'L',
              769.503,
              93.3669
            ],
            [
              'M',
              284.703,
              161.298
            ],
            [
              'L',
              284.703,
              114.845
            ],
            [
              'L',
              769.503,
              114.845
            ],
            [
              'M',
              306.195,
              161.298
            ],
            [
              'L',
              306.195,
              133.326
            ],
            [
              'L',
              769.503,
              133.326
            ]
          ],
          width: 505.79100000000005,
          height: 67.9311,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -55.25,
          left: -65.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#FDFFCD',
          strokeWidth: 1,
          path: [
            [
              'M',
              108.776,
              152.806
            ],
            [
              'L',
              242.721,
              152.806
            ],
            [
              'L',
              242.721,
              217.74
            ],
            [
              'L',
              108.776,
              217.74
            ],
            [
              'L',
              108.776,
              152.806
            ],
            [
              'Z'
            ]
          ],
          width: 133.945,
          height: 64.934,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: -51.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              124.769,
              165.793
            ],
            [
              'L',
              133.766,
              165.793
            ],
            [
              'L',
              133.766,
              199.758
            ],
            [
              'L',
              124.769,
              199.758
            ],
            [
              'L',
              124.769,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.996999999999986,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: -31.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              144.761,
              165.793
            ],
            [
              'L',
              153.758,
              165.793
            ],
            [
              'L',
              153.758,
              199.758
            ],
            [
              'L',
              144.761,
              199.758
            ],
            [
              'L',
              144.761,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.997000000000014,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: -11.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              162.754,
              165.793
            ],
            [
              'L',
              171.75,
              165.793
            ],
            [
              'L',
              171.75,
              199.758
            ],
            [
              'L',
              162.754,
              199.758
            ],
            [
              'L',
              162.754,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.99600000000001,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: 8.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              181.746,
              165.793
            ],
            [
              'L',
              190.742,
              165.793
            ],
            [
              'L',
              190.742,
              199.758
            ],
            [
              'L',
              181.746,
              199.758
            ],
            [
              'L',
              181.746,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.995999999999981,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: 28.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              200.738,
              165.793
            ],
            [
              'L',
              209.734,
              165.793
            ],
            [
              'L',
              209.734,
              199.758
            ],
            [
              'L',
              200.738,
              199.758
            ],
            [
              'L',
              200.738,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.99600000000001,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: 48.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              219.73,
              165.793
            ],
            [
              'L',
              228.727,
              165.793
            ],
            [
              'L',
              228.727,
              199.758
            ],
            [
              'L',
              219.73,
              199.758
            ],
            [
              'L',
              219.73,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.997000000000014,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -131.25,
          left: -172.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              0.320923,
              107.852
            ],
            [
              'L',
              2.81989,
              76.8838
            ],
            [
              'L',
              10.3168,
              76.8838
            ],
            [
              'L',
              7.81782,
              107.852
            ],
            [
              'L',
              79.7881,
              326.129
            ],
            [
              'L',
              93.2825,
              326.129
            ],
            [
              'L',
              93.2825,
              217.241
            ],
            [
              'L',
              256.715,
              217.241
            ],
            [
              'L',
              256.715,
              189.769
            ],
            [
              'L',
              317.69,
              189.769
            ],
            [
              'L',
              317.69,
              217.241
            ],
            [
              'L',
              480.622,
              217.241
            ],
            [
              'L',
              480.622,
              365.589
            ],
            [
              'L',
              93.2825,
              365.589
            ],
            [
              'L',
              93.2825,
              337.118
            ],
            [
              'L',
              75.7897,
              337.118
            ],
            [
              'L',
              0.320923,
              107.852
            ],
            [
              'Z'
            ]
          ],
          width: 480.301077,
          height: 288.7052,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -47.25,
          left: 87.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              259.714,
              162.297
            ],
            [
              'C',
              259.714,
              159.814,
              261.728,
              157.801,
              264.212,
              157.801
            ],
            [
              'C',
              266.696,
              157.801,
              268.71,
              159.814,
              268.71,
              162.297
            ],
            [
              'L',
              268.71,
              190.768
            ],
            [
              'L',
              259.714,
              190.768
            ],
            [
              'L',
              259.714,
              162.297
            ],
            [
              'Z'
            ]
          ],
          width: 8.995999999999981,
          height: 32.96700000000001,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -47.25,
          left: 108.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              280.705,
              162.297
            ],
            [
              'C',
              280.705,
              159.814,
              282.719,
              157.801,
              285.203,
              157.801
            ],
            [
              'C',
              287.687,
              157.801,
              289.701,
              159.814,
              289.701,
              162.297
            ],
            [
              'L',
              289.701,
              190.768
            ],
            [
              'L',
              280.705,
              190.768
            ],
            [
              'L',
              280.705,
              162.297
            ],
            [
              'Z'
            ]
          ],
          width: 8.996000000000038,
          height: 32.96700000000001,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -47.25,
          left: 129.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              301.696,
              162.297
            ],
            [
              'C',
              301.696,
              159.814,
              303.71,
              157.801,
              306.195,
              157.801
            ],
            [
              'C',
              308.679,
              157.801,
              310.693,
              159.814,
              310.693,
              162.297
            ],
            [
              'L',
              310.693,
              190.768
            ],
            [
              'L',
              301.696,
              190.768
            ],
            [
              'L',
              301.696,
              162.297
            ],
            [
              'Z'
            ]
          ],
          width: 8.996999999999957,
          height: 32.96700000000001,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -20.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              187.771
            ],
            [
              'L',
              269.71,
              187.771
            ],
            [
              'L',
              269.71,
              188.77
            ],
            [
              'L',
              258.714,
              188.77
            ],
            [
              'L',
              258.714,
              187.771
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -23.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              184.774
            ],
            [
              'L',
              269.71,
              184.774
            ],
            [
              'L',
              269.71,
              185.773
            ],
            [
              'L',
              258.714,
              185.773
            ],
            [
              'L',
              258.714,
              184.774
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              181.777
            ],
            [
              'L',
              269.71,
              181.777
            ],
            [
              'L',
              269.71,
              182.776
            ],
            [
              'L',
              258.714,
              182.776
            ],
            [
              'L',
              258.714,
              181.777
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -29.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              178.78
            ],
            [
              'L',
              269.71,
              178.78
            ],
            [
              'L',
              269.71,
              179.779
            ],
            [
              'L',
              258.714,
              179.779
            ],
            [
              'L',
              258.714,
              178.78
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -32.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              175.783
            ],
            [
              'L',
              269.71,
              175.783
            ],
            [
              'L',
              269.71,
              176.782
            ],
            [
              'L',
              258.714,
              176.782
            ],
            [
              'L',
              258.714,
              175.783
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -35.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              172.786
            ],
            [
              'L',
              269.71,
              172.786
            ],
            [
              'L',
              269.71,
              173.785
            ],
            [
              'L',
              258.714,
              173.785
            ],
            [
              'L',
              258.714,
              172.786
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -38.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              169.789
            ],
            [
              'L',
              269.71,
              169.789
            ],
            [
              'L',
              269.71,
              170.788
            ],
            [
              'L',
              258.714,
              170.788
            ],
            [
              'L',
              258.714,
              169.789
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -41.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              166.792
            ],
            [
              'L',
              269.71,
              166.792
            ],
            [
              'L',
              269.71,
              167.791
            ],
            [
              'L',
              258.714,
              167.791
            ],
            [
              'L',
              258.714,
              166.792
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -44.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              163.795
            ],
            [
              'L',
              269.71,
              163.795
            ],
            [
              'L',
              269.71,
              164.794
            ],
            [
              'L',
              258.714,
              164.794
            ],
            [
              'L',
              258.714,
              163.795
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -20.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              187.771
            ],
            [
              'L',
              290.701,
              187.771
            ],
            [
              'L',
              290.701,
              188.77
            ],
            [
              'L',
              279.705,
              188.77
            ],
            [
              'L',
              279.705,
              187.771
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -23.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              184.774
            ],
            [
              'L',
              290.701,
              184.774
            ],
            [
              'L',
              290.701,
              185.773
            ],
            [
              'L',
              279.705,
              185.773
            ],
            [
              'L',
              279.705,
              184.774
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              181.777
            ],
            [
              'L',
              290.701,
              181.777
            ],
            [
              'L',
              290.701,
              182.776
            ],
            [
              'L',
              279.705,
              182.776
            ],
            [
              'L',
              279.705,
              181.777
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -29.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              178.78
            ],
            [
              'L',
              290.701,
              178.78
            ],
            [
              'L',
              290.701,
              179.779
            ],
            [
              'L',
              279.705,
              179.779
            ],
            [
              'L',
              279.705,
              178.78
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -32.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              175.783
            ],
            [
              'L',
              290.701,
              175.783
            ],
            [
              'L',
              290.701,
              176.782
            ],
            [
              'L',
              279.705,
              176.782
            ],
            [
              'L',
              279.705,
              175.783
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -35.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              172.786
            ],
            [
              'L',
              290.701,
              172.786
            ],
            [
              'L',
              290.701,
              173.785
            ],
            [
              'L',
              279.705,
              173.785
            ],
            [
              'L',
              279.705,
              172.786
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -38.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              169.789
            ],
            [
              'L',
              290.701,
              169.789
            ],
            [
              'L',
              290.701,
              170.788
            ],
            [
              'L',
              279.705,
              170.788
            ],
            [
              'L',
              279.705,
              169.789
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -41.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              166.792
            ],
            [
              'L',
              290.701,
              166.792
            ],
            [
              'L',
              290.701,
              167.791
            ],
            [
              'L',
              279.705,
              167.791
            ],
            [
              'L',
              279.705,
              166.792
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -44.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              163.795
            ],
            [
              'L',
              290.701,
              163.795
            ],
            [
              'L',
              290.701,
              164.794
            ],
            [
              'L',
              279.705,
              164.794
            ],
            [
              'L',
              279.705,
              163.795
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -20.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              187.771
            ],
            [
              'L',
              311.692,
              187.771
            ],
            [
              'L',
              311.692,
              188.77
            ],
            [
              'L',
              300.697,
              188.77
            ],
            [
              'L',
              300.697,
              187.771
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -23.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              184.774
            ],
            [
              'L',
              311.692,
              184.774
            ],
            [
              'L',
              311.692,
              185.773
            ],
            [
              'L',
              300.697,
              185.773
            ],
            [
              'L',
              300.697,
              184.774
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              181.777
            ],
            [
              'L',
              311.692,
              181.777
            ],
            [
              'L',
              311.692,
              182.776
            ],
            [
              'L',
              300.697,
              182.776
            ],
            [
              'L',
              300.697,
              181.777
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -29.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              178.78
            ],
            [
              'L',
              311.692,
              178.78
            ],
            [
              'L',
              311.692,
              179.779
            ],
            [
              'L',
              300.697,
              179.779
            ],
            [
              'L',
              300.697,
              178.78
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -32.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              175.783
            ],
            [
              'L',
              311.692,
              175.783
            ],
            [
              'L',
              311.692,
              176.782
            ],
            [
              'L',
              300.697,
              176.782
            ],
            [
              'L',
              300.697,
              175.783
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -35.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              172.786
            ],
            [
              'L',
              311.692,
              172.786
            ],
            [
              'L',
              311.692,
              173.785
            ],
            [
              'L',
              300.697,
              173.785
            ],
            [
              'L',
              300.697,
              172.786
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -38.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              169.789
            ],
            [
              'L',
              311.692,
              169.789
            ],
            [
              'L',
              311.692,
              170.788
            ],
            [
              'L',
              300.697,
              170.788
            ],
            [
              'L',
              300.697,
              169.789
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -41.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              166.792
            ],
            [
              'L',
              311.692,
              166.792
            ],
            [
              'L',
              311.692,
              167.791
            ],
            [
              'L',
              300.697,
              167.791
            ],
            [
              'L',
              300.697,
              166.792
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -44.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              163.795
            ],
            [
              'L',
              311.692,
              163.795
            ],
            [
              'L',
              311.692,
              164.794
            ],
            [
              'L',
              300.697,
              164.794
            ],
            [
              'L',
              300.697,
              163.795
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -159.25,
          left: -500.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#00CDFB',
          strokeWidth: 1,
          path: [
            [
              'M',
              67,
              0
            ],
            [
              'L',
              0.5,
              0
            ],
            [
              'L',
              24.5,
              24.5
            ],
            [
              'L',
              322.5,
              72.0773
            ],
            [
              'L',
              396.847,
              298.748
            ],
            [
              'L',
              450.193,
              298.748
            ],
            [
              'L',
              450.193,
              312.666
            ],
            [
              'L',
              479.109,
              312.666
            ],
            [
              'L',
              479.109,
              341
            ],
            [
              'L',
              528.965,
              341
            ],
            [
              'L',
              528.965,
              317.14
            ],
            [
              'L',
              991.631,
              324.099
            ],
            [
              'L',
              1010,
              340.5
            ],
            [
              'L',
              1034.5,
              310
            ],
            [
              'L',
              980.5,
              310
            ],
            [
              'L',
              987,
              317
            ],
            [
              'L',
              556.885,
              309.187
            ],
            [
              'L',
              556.885,
              274.888
            ],
            [
              'L',
              450.193,
              274.888
            ],
            [
              'L',
              450.193,
              287.812
            ],
            [
              'L',
              405.322,
              287.812
            ],
            [
              'L',
              330.5,
              63.1297
            ],
            [
              'L',
              44.5,
              24.5
            ],
            [
              'L',
              67,
              0
            ],
            [
              'Z'
            ]
          ],
          width: 1034,
          height: 341,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -3.25,
          left: 37.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#FF2721',
          strokeLinejoin: 'round',
          strokeWidth: 4,
          path: [
            [
              'M',
              0.733582,
              83.6788
            ],
            [
              'L',
              73.1454,
              83.6788
            ],
            [
              'L',
              73.1454,
              12.6794
            ]
          ],
          width: 72.411818,
          height: 70.9994,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'circle',
          angle: 0,
          top: -3.25,
          left: 112.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'center',
          originY: 'center',
          radius: 10,
          fill: '#FF2721',
          strokeWidth: 1,
          width: 20,
          height: 20,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 45.75,
          left: -35.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          width: 75,
          height: 48,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 93.75,
          left: -4.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              38.7518,
              48.6544
            ],
            [
              'L',
              52.746,
              48.6544
            ],
            [
              'L',
              52.746,
              115.586
            ],
            [
              'L',
              45.7489,
              127.574
            ],
            [
              'L',
              38.7518,
              115.586
            ],
            [
              'L',
              38.7518,
              48.6544
            ],
            [
              'Z'
            ]
          ],
          width: 13.9942,
          height: 78.9196,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 125.75,
          left: -42.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              25.3789,
              79.2169
            ],
            [
              'C',
              23.3303,
              78.8342,
              21.0972,
              78.6239,
              18.76,
              78.6239
            ],
            [
              'C',
              8.823,
              78.6239,
              0.767456,
              82.4256,
              0.767456,
              87.1152
            ],
            [
              'C',
              0.767456,
              91.8049,
              8.823,
              95.6066,
              18.76,
              95.6066
            ],
            [
              'C',
              20.3632,
              95.6066,
              21.9174,
              95.5076,
              23.3967,
              95.3219
            ],
            [
              'C',
              18.7334,
              93.4897,
              15.7612,
              90.7174,
              15.7612,
              87.6147
            ],
            [
              'C',
              15.7612,
              84.0923,
              19.592,
              80.9957,
              25.3789,
              79.2169
            ],
            [
              'Z'
            ]
          ],
          width: 24.611444000000002,
          height: 16.982699999999994,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 125.75,
          left: 24.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              65.7407,
              79.2169
            ],
            [
              'C',
              67.7894,
              78.8342,
              70.0225,
              78.6239,
              72.3597,
              78.6239
            ],
            [
              'C',
              82.2967,
              78.6239,
              90.3522,
              82.4256,
              90.3522,
              87.1152
            ],
            [
              'C',
              90.3522,
              91.8049,
              82.2967,
              95.6066,
              72.3597,
              95.6066
            ],
            [
              'C',
              70.7565,
              95.6066,
              69.2023,
              95.5076,
              67.723,
              95.3219
            ],
            [
              'C',
              72.3863,
              93.4897,
              75.3585,
              90.7174,
              75.3585,
              87.6147
            ],
            [
              'C',
              75.3585,
              84.0923,
              71.5277,
              80.9957,
              65.7407,
              79.2169
            ],
            [
              'Z'
            ]
          ],
          width: 24.611499999999992,
          height: 16.982699999999994,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 127.75,
          left: -24.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              43.7497,
              80.6218
            ],
            [
              'C',
              30.8291,
              80.6218,
              18.76,
              83.1429,
              18.76,
              87.4852
            ],
            [
              'C',
              18.76,
              91.8274,
              30.4594,
              94.1081,
              43.7497,
              94.1081
            ],
            [
              'L',
              43.7497,
              80.6218
            ],
            [
              'Z'
            ]
          ],
          width: 24.989699999999996,
          height: 13.4863,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 127.75,
          left: -15.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              46.7485,
              80.6218
            ],
            [
              'C',
              60.0387,
              80.6218,
              71.7381,
              83.1429,
              71.7381,
              87.4852
            ],
            [
              'C',
              71.7381,
              91.8274,
              60.0387,
              94.1081,
              46.7485,
              94.1081
            ],
            [
              'L',
              46.7485,
              80.6218
            ],
            [
              'Z'
            ],
            [
              'M',
              23.6099,
              87.5021
            ],
            [
              'C',
              23.6099,
              87.4964,
              23.6098,
              87.4908,
              23.6098,
              87.4852
            ],
            [
              'C',
              23.6098,
              87.4796,
              23.6099,
              87.4739,
              23.6099,
              87.4683
            ],
            [
              'L',
              23.6099,
              87.5021
            ],
            [
              'Z'
            ]
          ],
          width: 48.1283,
          height: 13.4863,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: -28.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: -12.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: 4.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: 22.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        }
      ]
    }
  ],
  config: [
    {
      id: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
      canvasLibraryType: 'mountain',
      data: {
        draw: {}
      },
      devices: []
    }
  ]
}

const initialState = {
  dataloggers: [],
  loadData: false,
  addedDataloggers: [],
  assets: [],
  assetTypes: [],
  devices: [],
  maintenance: false,
  activeStep: 0,
  hasAutosave: false,
  plantType: 'hydro',
  selectedDatalogger: '',
  documents: [],
  anagraphic: {
    peakPower: '',
    plantCode: '',
    contractType: '',
    referentName: '',
    referentClient: '',
    referentRole: '',
    referentEmail: '',
    referentPhone: '',
    monitoringName: '',
    monitoringEmail: '',
    monitoringPhone: '',
    operationAndMaintenaceName: '',
    operationAndMaintenacePhone: '',
    operationAndMaintenaceEmail: ''
  },
  name: '',
  peakPower: '',
  startDate: null,
  contractDuration: 1,
  endDate: '',
  address: '',
  mapConfig: { zoom: 20, center: { lat: 0, lng: 0 } },
  planimetry: basePlanimetry,
  logoURL: null
}

const slice = createSlice({
  name: 'hydroPlantCreation',
  initialState,
  reducers: {
    setLoadData (state, actions) {
      const { loading } = actions.payload
      state.loadData = loading
    },
    setCurrentPlant (state, actions) {
      const { plant } = actions.payload
      const { mapConfig, hasAutosave, logoURL, name, uuid, anagraphic, devices, assets, assetTypes, addedDataloggers, address, contractDuration, endDate, startDate, maintenance, peakPower, planimetry, plantType, documents, ...rest } = plant
      const { mapConfig: initMapConfig, hasAutosave: initHasAutosave, documents: initDocuments, anagraphic: initAnagraphic } = initialState

      // L'impianto da modificare potrebbe non avere un oggetto mapConfig (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (mapConfig) {
        state.mapConfig = mapConfig
      } else {
        state.mapConfig = initMapConfig
      }

      // l'impianto da modificare potrebbe non avere un oggetto anagrafica (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (anagraphic) {
        state.anagraphic = anagraphic
        // altrimenti setto il valore di default dell'oggetto anagraphic che si utilizza per un nuovo impianto
      } else {
        state.anagraphic = initAnagraphic
      }

      // Se ci sono documenti salvati li setto altrimenti imposto l'array vuoto
      if (documents) {
        state.documents = documents
      } else {
        state.documents = initDocuments
      }
      // Se c'è il logo salvato lo setto altrimenti imposto l'oggetto a null
      if (logoURL) {
        state.logoURL = logoURL
      } else {
        state.logoURL = null
      }

      // Se c'è l'autosave attivato, lo setto
      if (hasAutosave) {
        state.hasAutosave = hasAutosave
      } else {
        state.hasAutosave = initHasAutosave
      }

      state.name = name
      state.uuid = uuid
      state.addedDataloggers = addedDataloggers
      state.devices = devices
      state.assets = assets
      state.assetTypes = assetTypes
      state.address = address
      state.contractDuration = contractDuration
      state.endDate = endDate || ''
      state.startDate = startDate || null
      state.maintenance = maintenance
      state.peakPower = peakPower
      state.planimetry = planimetry
      state.plantType = plantType
      state.loadData = false
      state.rest = rest
    },
    setAddress (state, action) {
      const { newAddress } = action.payload
      state.address = newAddress
    },
    setPlantType (state, action) {
      const { plantType } = action.payload
      state.plantType = plantType
    },
    setAutosave (state, action) {
      const { hasAutosave } = action.payload
      state.hasAutosave = hasAutosave
    },
    setLogo (state, action) {
      const { url } = action.payload
      if (state.uuid) {
        api.post({ savedUrl: 'forceSummaryRefresh', path: `/${state.uuid}/summary` })
      }
      state.logoURL = url
    },
    resetPlant (state) {
      const { anagraphic, hasAutosave, dataloggers, addedDataloggers, assetTypes, assets, maintenance, activeStep, plantType, selectedDatalogger, name, peakPower, startDate, contractDuration, endDate, address, planimetry, documents } = initialState
      if (state.uuid) {
        delete state.uuid
      }
      if (state.devices) {
        delete state.devices
      }
      state.dataloggers = dataloggers
      state.anagraphic = anagraphic
      state.addedDataloggers = addedDataloggers
      state.assets = assets
      state.assetTypes = assetTypes
      state.maintenance = maintenance
      state.activeStep = activeStep
      state.hasAutosave = hasAutosave
      state.plantType = plantType
      state.contractDuration = contractDuration
      state.selectedDatalogger = selectedDatalogger
      state.peakPower = peakPower
      state.startDate = startDate
      state.endDate = endDate
      state.documents = documents
      state.name = name
      state.logoURL = null
      state.address = address
      state.planimetry = planimetry
    },
    changeAnagraphicValues (state, action) {
      const { value, name } = action.payload
      if (state.anagraphic !== undefined) {
        if (state.anagraphic[name] !== undefined) {
          state.anagraphic[name] = value
        }
      }
    },
    changeValues (state, action) {
      const { value, name } = action.payload
      if (state[name] !== undefined) {
        state[name] = value
      }
    },
    setDeviceTypeDocuments (state, action) {
      const { documents, deviceTypeId } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      const updatedDataloggers = addedDataloggers.map(datalogger => {
        if (datalogger.includes && datalogger.includes.devices) {
          const devices = [...datalogger.includes.devices]
          const newDevices = devices.map(device => {
            const deviceType = device.deviceType
            if (deviceType && deviceType.uuid === deviceTypeId) {
              const newDeviceType = {
                ...deviceType,
                metadata: {
                  ...deviceType.metadata,
                  documents
                }
              }

              return {
                ...device,
                deviceType: newDeviceType
              }
            }

            return device
          })

          return {
            ...datalogger,
            includes: {
              ...datalogger.includes,
              devices: newDevices
            }
          }
        }

        return datalogger
      })
      state.addedDataloggers = updatedDataloggers
    },
    setPlantDocuments (state, action) {
      const { documents } = action.payload
      state.documents = documents
    },
    setPlanimetry (state, action) {
      const { list } = action.payload
      state.planimetry = list
    },
    setStep (state, action) {
      const { step } = action.payload
      state.activeStep = step
    },
    setDataloggers (state, action) {
      const { dataloggers } = action.payload
      state.dataloggers = dataloggers
    },
    removeDatalogger (state, action) {
      const { dataloggerId } = action.payload
      // Datalogger disponibili
      const currentDataloggers = Object.assign([], state.dataloggers)
      // Datalogger assegnati all'impianto
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const currentDistribution = JSON.parse(JSON.stringify(state.distribution))
      const currentPlanimetryConfig = JSON.parse(JSON.stringify(state.planimetry.config))
      const selectedDataloggerIndex = addedDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = addedDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        // Rimetto l'oggetto datalogger solo se non è già presente nell'array dei datalogger disponibili
        const existingDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
        if (!existingDatalogger) {
          currentDataloggers.push(selectedDatalogger[0])
        }

        // svuoto le distribuzioni create per i devices sottesi al datalogger eliminato
        const devices = selectedDatalogger[0].includes && selectedDatalogger[0].includes.devices

        devices.forEach(device => {
          if (currentDistribution[device.uuid] && currentDistribution[device.uuid] !== undefined) {
            delete currentDistribution[device.uuid]
          }

          currentPlanimetryConfig.forEach(configElement => {
            if (configElement.devices) {
              // rimuovo i devices dall'array devices di ogni elemento di configurazione
              const newDevices = configElement.devices.filter(el => el.deviceId !== device.uuid)
              // assegno il nuovo array di devices filtrato
              configElement.devices = newDevices
            }
          })
        })

        state.distribution = currentDistribution
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        state.planimetry.config = currentPlanimetryConfig
      }
    },
    updateAddedDatalogger (state, action) {
      const { dataloggerId, newDevice } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      // Filtro tutti i datalogger che non sono quello selezionato
      const otherDataloggers = addedDataloggers.filter(el => el.uuid !== dataloggerId)
      // Prendo il datalogger selezionato
      const selectedDatalogger = addedDataloggers.find(el => el.uuid === dataloggerId)
      // console.log('datasheet - selectedDatalogger: ', selectedDatalogger)
      // Se il datalogger selezionato esiste
      if (selectedDatalogger && selectedDatalogger !== undefined) {
        // Se il datalogger ha l'array di device
        if (selectedDatalogger.includes && selectedDatalogger.includes.devices) {
          // Prendo tutti i devices di questo datalogger
          const dataloggerDevices = JSON.parse(JSON.stringify(selectedDatalogger.includes.devices))
          const newDataloggerDevices = dataloggerDevices.map(el => {
            if (el.uuid === newDevice.uuid) {
              el.metadata.documents = newDevice.metadata.documents
            }

            return el
          })
          selectedDatalogger.includes.devices = newDataloggerDevices
          otherDataloggers.push(selectedDatalogger)
          // console.log('datasheet - otherDataloggers: ', otherDataloggers)
          state.addedDataloggers = otherDataloggers
        }
      }
    },
    selectDatalogger (state, action) {
      const { dataloggerId } = action.payload
      const currentDataloggers = Object.assign([], state.dataloggers)
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const selectedDataloggerIndex = currentDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = currentDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        addedDataloggers.push(selectedDatalogger[0])
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        // console.log('PlantComponent - devices on addedDataloggers: ', state.addedDataloggers)
      }
    },
    updateMapConfig (state, action) {
      const { center, zoom } = action.payload
      state.mapConfig = {
        zoom,
        center
      }
    }
  }
})

export const reducer = slice.reducer

export const updateAddedDatalogger = (dataloggerId, newDevice) => dispatch => {
  dispatch(slice.actions.updateAddedDatalogger({ dataloggerId, newDevice }))
}

export const setStep = (step) => dispatch => {
  dispatch(slice.actions.setStep({ step }))
}

export const savePlanimetry = (list) => dispatch => {
  dispatch(slice.actions.setPlanimetry({ list }))
}

export const updatePlantDocuments = (documents) => dispatch => {
  dispatch(slice.actions.setPlantDocuments({ documents }))
}

export const updateDeviceTypeDocuments = (documents, deviceTypeId) => dispatch => {
  dispatch(slice.actions.setDeviceTypeDocuments({ documents, deviceTypeId }))
}

export const addDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.selectDatalogger({ dataloggerId }))
}

export const deleteDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.removeDatalogger({ dataloggerId }))
}

export const getDataloggers = ({ skip = 0, limit = 50, filter = null, hasNoPlant = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC',
    include: 'devices'
  }

  if (filter) {
    params.filter = filter
  }

  if (hasNoPlant !== null) {
    params.hasNoPlant = hasNoPlant
  }

  const { data: dataloggers } = await api.get({ savedUrl: 'nodesList', params })
  if (dataloggers) {
    dispatch(slice.actions.setDataloggers({ dataloggers }))
  }
}

export const createPlant = (plant, reset = true, userIsSaving = false) => async dispatch => {
  const { uuid, assets, devices } = plant
  log({ text: 'createPlant - plant => ', variable: plant, tag: 'pvPlantSlice' })

  const body = encodeForApi(plant)
  log({ text: 'encodeForApi - body => ', variable: JSON.stringify(body), tag: 'pvPlantSlice' })

  const { data: response, error } = await api.post({ savedUrl: 'dashboard', path: '/plants', body })
  if (error) {
    console.error('creation plant error: ', error)
    return { createdPlant: null, error: true }
  }

  const createdPlant = { ...response }

  if (createdPlant.uuid) {
    for (const asset of assets) {
      // Controllo se ha un uuid e se è stato modificato
      if (asset.uuid && asset.dirty) {
        // Comparo il valore delle additionalProperties con quello del device selezionato, se non è uguale faccio una PUT sul device
        const deviceId = asset?.propertyMappings?.adc?.resourceId ?? ''
        const currentDevice = devices.find(device => device.uuid === deviceId) ?? {}
        const deviceAdditionalPropertiesObj = currentDevice.metadata?.additionalProperties.find(obj => obj.sensorAdc === asset.additionalProperties.sensorAdc) ?? {}
        if (Object.keys(deviceAdditionalPropertiesObj).length > 0 && Object.keys(asset.additionalProperties).length > 0 && !deepEqual(deviceAdditionalPropertiesObj, asset.additionalProperties)) {
          const otherDeviceAdditionalProperties = currentDevice.metadata?.additionalProperties.filter(el => el.sensorAdc !== asset.additionalProperties.sensorAdc)
          const newAdditionalProperties = [...otherDeviceAdditionalProperties, { ...asset.additionalProperties }]
          const editableDevice = {
            ...currentDevice,
            metadata: {
              ...currentDevice.metadata,
              additionalProperties: newAdditionalProperties
            }
          }

          const { error: deviceError } = await api.put({
            savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/devices/${deviceId}`,
            body: editableDevice
          })

          if (deviceError) {
            break
          }
        }
        delete asset.additionalProperties
        delete asset.dirty
        const { error: assetsError } = await api.put({
          savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/assets/${asset.uuid}`,
          body: asset
        })

        if (assetsError) {
          break
        }
      } else if (asset.id && !asset.uuid) {
        const propertyMappingGenericKey = Object.keys(asset?.propertyMappings).find(key => key.includes('adc')) ?? ''
        const deviceId = asset?.propertyMappings?.[propertyMappingGenericKey]?.resourceId ?? ''
        const currentDevice = devices.find(device => device.uuid === deviceId) ?? {}
        const deviceAdditionalPropertiesObj = currentDevice.metadata?.additionalProperties.find(obj => obj.sensorAdc === asset.additionalProperties.sensorAdc) ?? {}
        if (Object.keys(deviceAdditionalPropertiesObj).length > 0 && Object.keys(asset.additionalProperties).length > 0 && !deepEqual(deviceAdditionalPropertiesObj, asset.additionalProperties)) {
          const otherDeviceAdditionalProperties = currentDevice.metadata?.additionalProperties.filter(el => el.sensorAdc !== asset.additionalProperties.sensorAdc)
          const newAdditionalProperties = [...otherDeviceAdditionalProperties, { ...asset.additionalProperties }]
          const editableDevice = {
            ...currentDevice,
            metadata: {
              ...currentDevice.metadata,
              additionalProperties: newAdditionalProperties
            }
          }
          const { error: deviceError } = await api.put({
            savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/devices/${deviceId}`,
            body: editableDevice
          })

          if (deviceError) {
            break
          }
        }
        delete asset.additionalProperties
        delete asset.dirty
        const { error: assetsError } = await api.post({
          savedUrl: `https://api.apio.network/projects/${createdPlant?.projectId}/assets`,
          body: { ...asset, plantId: createdPlant.uuid }
        })

        if (assetsError) {
          break
        }
      }
    }
  }

  if (!uuid || userIsSaving) {
    const { data: summaryRefreshResponse } = await api.post({ savedUrl: 'forceSummaryRefresh', path: `/${createdPlant.uuid}/summary` })
    log({ text: 'summaryRefreshResponse => ', variable: summaryRefreshResponse, tag: 'pvPlantSlice' })
  }
  if (reset) {
    dispatch(slice.actions.resetPlant())
  }
  return { createdPlant, error: false }
}

export const resetInitState = () => dispatch => {
  dispatch(slice.actions.resetPlant())
}

export const getCurrentPlant = (plantId, projectId, firstLoad = false) => async dispatch => {
  if (plantId) {
    if (firstLoad) {
      dispatch(slice.actions.setLoadData({ loading: true }))
    }
    const options = {
      path: `/plants/${plantId}`
    }

    const { data: response } = await api.get({ savedUrl: 'dashboard', ...options })

    const { data: assetTypes } = await api.get({
      savedUrl: `https://api.apio.network/projects/${projectId}/assetTypes`
    })

    const { data: assets } = await api.get({
      savedUrl: `https://api.apio.network/projects/${projectId}/assets`,
      params: { filter: { plantId } }
    })

    const { plant, dataloggers, devices } = response || {}
    const adaptedPlant = decodeFromApi({ assets, assetTypes, plant, dataloggers, devices })
    dispatch(slice.actions.setCurrentPlant(adaptedPlant))
  }
}

export const changeContractValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeValues({ value, name }))
}

export const changeAnagraphicValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeAnagraphicValues({ value, name }))
}

export const changePlantType = (plantType) => dispatch => {
  dispatch(slice.actions.setPlantType({ plantType }))
}

export const setAddress = (newAddress) => dispatch => {
  dispatch(slice.actions.setAddress({ newAddress }))
}

export const updateLogo = (url = null) => dispatch => {
  dispatch(slice.actions.setLogo({ url }))
}

export const togglePlantAutosave = (hasAutosave) => dispatch => {
  dispatch(slice.actions.setAutosave({ hasAutosave }))
}

// Funzione che setta la configurazione della mappa della planimetria
export const updateMapConfig = ({ center, zoom }) => dispatch => {
  dispatch(slice.actions.updateMapConfig({ center, zoom }))
}
